import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface MobileInputProps {
    value : string;
    onChange : (_ : string) => void;
}
export default function MobileInput(props : MobileInputProps) {

    return (
        <PhoneInput
                country={"eg"}                
                countryCodeEditable={false}
                value={props.value} 
                onChange={(s) => props.onChange('+' + s)} 
                // Set your inline styles here
                buttonStyle={{
                    height: '100%'
                }}
                containerStyle={{
                    height: '50px'
                }}
                inputStyle={{
                background: "white",
                width: '100%',
                height: '100%'
                }}
            />
    );
}