import settings from '../settings.json';
import Axios from 'axios';
import { nilStudent, serializeStudent, StudentModel } from '../models/users';



const UsersService = {
    async addStudent(student : StudentModel) : Promise<StudentModel> {
        const url = settings.baseUrl + 'api/user/student/register';
        var res = null;
        try {
            console.log(serializeStudent(student));
            res = await Axios.post(url, serializeStudent(student));
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
            }
            else {
                console.log(err.response?.data);
                throw err.response?.data;
            }
            window.location.reload();
            console.log(err.response);
            return nilStudent;
        }
        try {
            res.data['auth_user'] = {
                id: res.data['id'],
                email: res.data['email'],
            };
            return new StudentModel(res.data);
        }
        catch(err) {
            console.error(err);
            throw new Error('Unexpected response format');
        }
    },
}


export default UsersService;