import React, {useState} from 'react';
import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component';
import RegisterPage from './components/register/register';
import './App.css';
import { nilStudent } from './models/users';
import { GenderModel } from './models/gender';
import OptionsService from './services/options.service';
import { logAllErrors } from './utils/utils';
import UsersService from './services/users.service';
import { ScaleLoader } from 'react-spinners';
import { purpleColor } from './constants';
import FormContainer from './basic-components/form-container/form-container';
import SuccessfulRegisterationPage from './components/successful-registeration/successful-registeration';
interface LoadingProgress {
  genders : boolean;
}; 

function App() {
  
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [allGenders, setAllGenders] = useState<Array<GenderModel>>([]);
  const [registerationDone, setRegisterationDone] = useState<boolean>(false);
  const serverDataSettersCollection = {
    'genders': setAllGenders,
  }

  React.useEffect(() => {
      // this prevents the component of being rendered recursively 
      // every time coreContext.setLoading is called
      if(loaded) return;
      setLoaded(true);
      const loadingProgress : LoadingProgress = {
          'genders': false,
      };
      const loadingStatus = () => { return !loadingProgress.genders }
      setLoading(true);

      const handleFetchedData = (key : keyof LoadingProgress, data :any ) => {
          loadingProgress[key] = true;
          serverDataSettersCollection[key](data);
          setLoading(loadingStatus());
      }


      /**Load All Genders**/
      OptionsService.fetchAllGenders().then((genders) => {
        handleFetchedData('genders', genders);
      }).catch(err => {
          console.error(err);
          logAllErrors(err);
      });

      // eslint-disable-next-line
  }, [])
  const onRegisterationDone = () => {
    setRegisterationDone(true);
  };

  if(loading) {
    return (
      <div className="spinner-container"> 
        <ScaleLoader color={purpleColor}/>
      </div>
    )
  }

  if(registerationDone) {
    return (
      <div>
        <ReactNotification />
        <FormContainer title="" subtitle="">
          <SuccessfulRegisterationPage/>
        </FormContainer>
      </div>
    );
  }
  return (
    <div>
      <ReactNotification />
      <FormContainer title="Registeration" subtitle="Sign up for a student account">
        <RegisterPage student={nilStudent} genders={allGenders} onSave={onRegisterationDone} serviceFunc={UsersService.addStudent}/>
      </FormContainer>
    </div>
  );
}

export default App;
