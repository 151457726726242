import React, {useEffect, useState} from 'react';
import { AuthUserModel, StudentModel, StudentParentModel } from '../../models/users';
import './register.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { GenderModel } from '../../models/gender';
import OutlinedSelect from '../../basic-components/outlined-select/outlined-select';
import OutlinedDatePicker from '../../basic-components/outlined-datepicker/outlined-datepicker';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { logAllErrors, logSuccess } from '../../utils/utils';
import ScaleLoader from 'react-spinners/ScaleLoader';
import {purpleColor} from '../../constants';
import PhoneInput from '../../basic-components/mobile-no-input/mobile-no-input';
import { ClassCategoryModel } from '../../models/classes';
import ClassService from '../../services/classes.services'

type ParentRelationship =  'father' | 'mother'

const allParentsTypes = ['father', 'mother']

const stringToParent = (s?: string): ParentRelationship | undefined => {
    switch(s?.toLowerCase()) {
        case 'father': return 'father'
        case 'mother': return 'mother'
        default: return undefined
    }
}

const upperCaseFirstLetter = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      color: 'white',
      backgroundColor: '#1a8c01'
    },
    phone_input: {
        height: '300px'
    }
  }),
);

interface EditPageProps {
    student : StudentModel;
    onSave : (_ : StudentModel) => void;
    genders : Array<GenderModel>;
    serviceFunc : (_ : StudentModel) => Promise<StudentModel>;
};
export default function EditStudentPage(props : EditPageProps) {
    /******Loading Animation********/
    const [loading, setLoading] = useState<boolean>(false);
    const renderLoader = () => {
        if(loading)
            return (
                <div className="spinner-container">
                    <ScaleLoader color={purpleColor}/>
                </div>
            );
        return (null);
    }




    /************User Management***************/
    const classes = useStyles();
    const [student, setStudent] = useState<StudentModel>(props.student);
    const [parent, setParent] = useState<ParentRelationship | undefined>(undefined)

    const [allClassCategories, setAllClassCategories] = useState<ClassCategoryModel[]>([]);

    useEffect(() => {
      ClassService
	.fetchAllClassCategories()
	.then(setAllClassCategories)
	.catch(logAllErrors)
    }, [])

    const setRequiredField = (key : keyof StudentModel, value : string | Date | AuthUserModel) => {
        setStudent({...student, [key]: value});
    }

    const setOptionalField = (key : keyof StudentModel, value? : string | StudentParentModel) => {
        if(value === undefined || value === '')
            setStudent({...student, [key]: undefined});
        else
            setStudent({...student, [key]: value});
    }

    const isNull = (key : keyof StudentParentModel, value? : StudentParentModel) => {
        return (!value || !value[key] || value[key] === '');
    }
    const validate = () => {
        var count = 0;
        const keys : Array<keyof StudentParentModel> = ['name', 'email'];
        var motherCount = keys.filter(key => !isNull(key, student.mother)).length;
        var fatherCount = keys.filter(key => !isNull(key, student.father)).length;
        student.father = fatherCount > 0 ? student.father : undefined;
        student.mother = motherCount > 0 ? student.mother : undefined;
        count = motherCount + fatherCount;

        if(count === 0){
            logAllErrors('You need to supply at least one of the parents');
            return false;
        }
        if(count % 2 === 1) {
            logAllErrors('You need to supply both the email and the name for the parent(s) you supplied');
            return false;
        }
        return true;
    }
    const onSave = async () => {
        if(!validate()) return;
        setLoading(true);
        console.log(student);
        props.serviceFunc(student).then(_ => {
            logSuccess('You have been registered Successfully!');
            props.onSave(student);
        }).catch(err => {
            logAllErrors(err);
            console.error(err);
        }).finally(() => setLoading(false));
    }

    return (
        <div className="student-page-container">
            <div className="form-container">
                <h3>Personal Info</h3>
                <TextField error={student.firstName === ''} id="outlined-basic" label="First Name" variant="outlined" value={student.firstName} onChange={(event) => { setRequiredField('firstName', event.target.value || '')}} fullWidth/>
                <TextField error={student.lastName === ''} id="outlined-basic" label="Last Name" variant="outlined"  value={student.lastName} onChange={(event) => { setRequiredField('lastName', event.target.value || '')}} fullWidth/>
                <TextField id="outlined-basic" label="Nickname" variant="outlined"  value={student.nickName || ''} onChange={(event) => { setOptionalField('nickName', event.target.value || '')}} fullWidth/>
                <OutlinedSelect value={student.gender} optional={false} label="Gender" items={props.genders.map(gender => gender.gender)} onChange={(event) => { setRequiredField('gender', event.target.value as string || '')} }/>
                <OutlinedDatePicker value={student.birthdate} label="Birthdate" onChange={(event) => { setRequiredField('birthdate', event || student.birthdate)}}/>
            </div>
            <div className="form-container">
                <h3>Contacts Info</h3>
                <TextField  error={student.authUser.email === ''} id="outlined-basic" label="Email" variant="outlined" value={student.authUser.email}  onChange={(event) => { setRequiredField('authUser', {email: event.target.value.toLowerCase() || '', id: student.authUser.id})} } fullWidth/>
                <PhoneInput  value={student.phoneNumber || ''} onChange={(event) => { setOptionalField('phoneNumber', event|| '')} }/>
                <TextField id="outlined-basic" label="Facebook Account Name" variant="outlined" value={student.facebookUrl}  onChange={(event) => { setRequiredField('facebookUrl', event.target.value.toLowerCase() || '') }} fullWidth/>
            </div>
            <div className="form-container">
                <h3>Parent Info</h3>
                <OutlinedSelect value={parent || 'Please select a parent'} optional={false} label="Parent" items={allParentsTypes.map((s) => upperCaseFirstLetter(s))} values={allParentsTypes} onChange={(event) => { setParent(stringToParent((event.target.value as string)))} }/>
                { parent && (<><TextField id="outlined-basic" label="Parent's Name" variant="outlined" value={student[parent]?.name || ''} onChange={(event) => { setOptionalField(parent, {name: event.target.value || '',  email: student[parent]?.email || ''})} } fullWidth/>
                <TextField id="outlined-basic" label="Parent's Email" variant="outlined"  value={student[parent]?.email || ''}  onChange={(event) => { setOptionalField(parent, {name: student[parent]?.name || '',  email: event.target.value.toLowerCase()})} } fullWidth/>
                <PhoneInput value={student[parent]?.phoneNumber || ''}  onChange={(event) => { setOptionalField(parent, {name: student[parent]?.name || '', email: student[parent]?.email || '', phoneNumber: event})}}/></>) }
            </div>
            <div className="form-container">
                <h3>School Info</h3>
                <TextField id="outlined-basic" label="School Name" variant="outlined"  value={student.school || ''}  onChange={(event) => { setOptionalField('school', event.target.value || '')}} fullWidth/>
	    </div>
	    <div className="form-container">
		<h3>Course Info</h3>
		<OutlinedSelect value={student.courseType || 'Please Select Your Course'} optional={false} label="courseType" items={allClassCategories.map((s) => upperCaseFirstLetter(s.name))} values={allClassCategories.map(c => c.name)} onChange={(event) => { setOptionalField('courseType', event.target.value as string)} }/>
	    </div>

            <div className="edit-student-submit">
                <Button
                    variant="contained"
                    color="inherit"
                    size="large"
                    className={classes.button}
                    startIcon={<PersonAdd />}
                    onClick={onSave}
                >
                    Sign Up
                </Button>
            </div>
            {renderLoader()}
        </div>

    )
}
