import React from 'react';
import './form-container.css';


interface FormContainerProps {
    title : string;
    subtitle : string;
    children : any;
}

function FormContainer(props : FormContainerProps){

    
    ///////////////////////////////////
    // HTML
    ///////////////////////////////////
    return (
        <div className="Login-container">
        <div className="Login-box">
            <div className="Box-left-section">
                <h2 className="title">{props.title}</h2>
                <h4 className="prompt">{props.subtitle}</h4>
                {props.children}
            </div>
            <div className="Box-right-section">
                    <img className="logo-img" src="https://firebasestorage.googleapis.com/v0/b/minaportal.appspot.com/o/logo-inverse.png?alt=media&token=91355777-cc1c-4277-a591-b13405d81c85" alt="logo"/>
            </div>
        </div>
    </div>
    );
}

export default FormContainer;