import { DateModel, makeDateStr } from './Date';
import { checkAllRequiredFields } from '../utils/utils';

export class AuthUserModel {
    id : number;
    email : string;

    constructor(authUserObj : any) {
        checkAllRequiredFields(authUserObj, ['id', 'email']);
        this.id = authUserObj.id;
        this.email = authUserObj.email;
    }

}



export class UserModel {
    authUser : AuthUserModel;
    firstName : string;
    lastName : string;
    nickName? : string;
    gender : string;
    birthdate : DateModel;
    phoneNumber? : string;
    photoUrl? : string;
    facebookUrl?: string;
    constructor(userObj : any){
        /** Check all the required fields are present **/
        checkAllRequiredFields(userObj, ['auth_user', 'first_name', 'last_name', 'gender', 'birthdate']);

        this.authUser = new AuthUserModel(userObj['auth_user']);
        this.firstName = userObj['first_name'];
        this.lastName = userObj['last_name'];
        this.nickName = userObj['nickname'];
        this.gender = userObj['gender'];
        this.birthdate = new DateModel(userObj['birthdate']);
        this.phoneNumber = userObj['phone_number'];
        this.photoUrl = userObj['photo_url'];
        this.facebookUrl = userObj['facebook_url'];
     }

}

export function serializeUser(user : UserModel) {
    return {
        id: user.authUser.id,
        email: user.authUser.email,
        auth_user: {
            id: user.authUser.id,
            email: user.authUser.email
        },
        first_name: user.firstName,
        last_name: user.lastName,
        nickname: user.nickName,
        gender: user.gender,
        birthdate: makeDateStr(user.birthdate),
        phone_number: user.phoneNumber,
        photo_url: user.photoUrl,
        facebook_url: user.facebookUrl
    }
}

export class StudentParentModel {
    name : string;
    email : string;
    phoneNumber? : string;
    constructor(obj : any) {
        checkAllRequiredFields(obj, ['name', 'email']);
        this.name = obj['name'];
        this.email = obj['email'];
        this.phoneNumber = obj['phone_number'];
    }
}


function checkMotherOrFather(obj : any) {
    if(!('father' in obj) && !('mother' in obj))
        throw new Error('Information for at least one of the parents must be supplied!');
}


export class StudentModel extends UserModel {
    father? : StudentParentModel;
    mother? : StudentParentModel;
    school? : string;

    courseType? : string;

    constructor(obj : any) {
        super(obj);
        checkMotherOrFather(obj);
        if('mother' in obj && obj['mother']) this.mother = new StudentParentModel(obj['mother']);
        if('father' in obj && obj['father']) this.father = new StudentParentModel(obj['father']);
        this.school = obj['school'];
	this.courseType = obj['course_type']
    }
}


export function serializeParent(studentParent? : StudentParentModel) {
    if(!studentParent) return null;
    return {
        name: studentParent.name,
        email: studentParent.email,
        phone_number: studentParent.phoneNumber
    }
}
export function serializeStudent(student : StudentModel) {
    return {
        id: student.authUser.id,
        email: student.authUser.email,
        auth_user: {
            id: student.authUser.id,
            email: student.authUser.email
        },
        first_name: student.firstName,
        last_name: student.lastName,
        nickname: student.nickName,
        gender: student.gender,
        birthdate: makeDateStr(student.birthdate),
        facebook_url: student.facebookUrl,
        phone_number: student.phoneNumber,
        photo_url: student.photoUrl,
        school: student.school,
        father: serializeParent(student.father),
        mother: serializeParent(student.mother),
	course_type: student.courseType
    }
}

export const nilStudent : StudentModel = {
    authUser: {
        email: '',
        id: 0
    },
    firstName: '',
    lastName : '',
    birthdate: new Date(),
    gender: ''
}
export const nilUser : UserModel = {

    authUser: {
        email: '',
        id: 0
    },
    firstName: '',
    lastName : '',
    birthdate: new Date(),
    gender: ''
}
