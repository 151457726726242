import React from 'react';
import './successful-registeration.css';

function SuccessfulRegisterationPage() 
{
    return (
        <div className="success-container">
            <img className="success-image" src="https://firebasestorage.googleapis.com/v0/b/minaportal.appspot.com/o/success.png?alt=media&token=c5f8442c-6aa1-4afb-93ab-731343c1cd30" alt="success"/>
            <h1 className="success-title">Thank you for your Input</h1>
            <p className="body">Please check your inbox/spam for an email with the <b>verification link </b> and <b>choose a new Password </b>. 
                Once Dr. Mina Adly's team review your data and enroll you in a class, you will receive another email with some instructions and you will
                be able to login in to the Mobile Application with your account.
            </p>
            <h2 className="regards">Best Of Luck,</h2>
            <h2 className="regards">Dr. Mina Adly and the Team</h2>
        </div>
    );
}

export default SuccessfulRegisterationPage;
